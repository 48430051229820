import { apiGet, apiPost } from './index'

export async function listPanel(data) {
	return await apiPost("/api/Panel/list", data);
}

export async function fullListPanel(data) {
	return await apiPost("/api/Panel/fulllist", data);
}

export function getPanel(data) {
	let url = "/api/Panel/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postPanel(data) {
	return apiPost("/api/Panel/edit", data);
}

export function addPanel(data) {
	return apiPost("/api/Panel/add", data);
}

export function deletePanel(data) {
	return apiGet("/api/Panel/delete/" + data);
}
